// src/pages/Service.js
import React from "react";

function Service() {
  return (
    <div className="service">
      <h4>Food Service Main</h4>
      <p>Information about food services...</p>
    </div>
  );
}

export default Service;
