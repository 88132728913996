// src/components/Layout.js
import React from "react";
import { Link, Outlet } from "react-router-dom";

function Layout() {
  return (
    <div className="layout">
      <table className="layout-table">
        <tbody>
          <tr>
            <td>
              <header>
                <img
                  src="/images/hlacollage.jpg"
                  alt="HLA Collage"
                  width="800"
                  height="102"
                />
              </header>

              <nav>
                <table
                  width="800"
                  height="40"
                  style={{
                    background: `url('/images/navbak.gif')`,
                    tableLayout: "fixed", // Ensures even distribution of columns
                    textAlign: "center", // Centers content within the cells
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <Link to="/">
                          <img
                            src="/images/home.gif"
                            alt="Home"
                            width="48"
                            height="17"
                          />
                        </Link>
                      </td>
                      <td>
                        <Link to="/about">
                          <img
                            src="/images/about.gif"
                            alt="About"
                            width="90"
                            height="17"
                          />
                        </Link>
                      </td>
                      <td>
                        <Link to="/contact">
                          <img
                            src="/images/contact.gif"
                            alt="Contact"
                            width="110"
                            height="17"
                          />
                        </Link>
                      </td>
                      <td>
                        <a href="/vendor">
                          <img
                            src="/images/vendor.gif"
                            alt="Vendor"
                            width="180"
                            height="17"
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </nav>

              <main>
                <div className="content">
                  <Outlet />
                </div>
              </main>

              <footer>
                <table width="100%" height="20" border="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="31%">&nbsp;</td>
                      <td width="38%">
                        <p align="center">
                          <nobr>
                            <font
                              color="#FFFFFF"
                              size="2"
                              face="Arial, Helvetica, sans-serif"
                            >
                              <Link to="/" className="footer">
                                Home
                              </Link>{" "}
                              |
                              <Link to="/about" className="footer">
                                About
                              </Link>{" "}
                              |
                              <Link to="/contact" className="footer">
                                Contact
                              </Link>{" "}
                              |
                              <Link to="/vendor" className="footer">
                                Vendor Partnerships
                              </Link>
                            </font>
                          </nobr>
                        </p>
                      </td>
                      <td width="31%">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </footer>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Layout;
