// src/components/BrandPartners.js
import React from "react";
import "./BrandPartners.css";

// Function to require all images from the folder
function importAll(r) {
  return r.keys().map(r);
}

const brandLogos = importAll(
  require.context("../../public/images/partners", false, /\.(png|jpe?g|gif)$/)
);

function BrandPartners() {
  return (
    <div className="brand-collage">
      {brandLogos.map((logo, index) => (
        <div key={index} className="brand-item">
          <img src={logo} alt={`Brand ${index + 1}`} />
        </div>
      ))}
    </div>
  );
}

export default BrandPartners;
