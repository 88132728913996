// src/pages/Vendor.js
import React from "react";
import BrandPartners from "../components/BrandPartners";
import "./Vendor.css"; // Import the CSS file

function Vendor() {
  return (
    <div className="vendor-page">
      <h2>Our Brand Partners</h2>
      <BrandPartners />
      <p>
        We proudly partner with the following brands to bring the best products
        to convenience store operators across the region. These partnerships
        allow us to offer a wide selection of quality goods to meet the diverse
        needs of our customers. Our goal is to help our partners grow while
        ensuring our customers have access to the best products in the market.
      </p>

      <p>
        At Dvash, we believe that strong partnerships are the foundation of
        success. We collaborate closely with our partners to ensure they benefit
        from our vast distribution network, helping their products reach
        thousands of convenience stores. We also work on co-marketing
        initiatives, product placement, and merchandising strategies to help our
        partners thrive in a competitive market.
      </p>

      <p>
        If you would like to partner with Dvash and grow your brand with us,
        please don't hesitate to reach out. We are always looking to form new,
        mutually beneficial relationships with brands that align with our values
        and vision. You can contact our sales team for more information and to
        explore partnership opportunities.
      </p>

      <p>
        We are committed to maintaining long-term partnerships built on trust,
        transparency, and shared success. Our dedicated team of professionals
        ensures that every partnership is nurtured and supported, giving your
        brand the exposure and resources it needs to thrive. Many of our current
        partners have experienced significant growth by partnering with Dvash,
        and we’re excited to help many more brands succeed in the market.
      </p>

      <p>
        Interested in partnering with Dvash? Reach out to us today, and let’s
        build something great together!
      </p>
    </div>
  );
}

export default Vendor;
