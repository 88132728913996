// src/pages/About.js
import React from "react";
import "./About.css"; // Ensure this import is correct

function About() {
  return (
    <div className="about-page">
      <h2>About Dvash</h2>
      <p>
        <strong>Dvash </strong>was founded with a simple vision:{" "}
        <strong>
          to provide convenience store operators with the products and services
          they need to succeed
        </strong>
        . Starting from modest beginnings, we’ve grown into a trusted
        distributor, offering a wide selection of goods to meet the diverse
        needs of our customers.
      </p>
      <p>
        Our dedicated team works hard every day to ensure we deliver reliable,
        quality service. With thousands of products available, we act as the{" "}
        <strong>
          crucial link between manufacturers and convenience store owners
        </strong>
        , helping them stay stocked with the latest and most essential items.
      </p>
      <p>
        At <strong>Dvash</strong>, we take pride in offering value beyond just
        products. We focus on supporting our customers by{" "}
        <strong>helping them maximize profits and stay competitive</strong> in
        an ever-evolving market. This includes personalized service, expert
        advice on product selection, and assistance with merchandising. Whether
        it’s arranging effective product displays or providing tips for in-store
        operations, we are here to support your business every step of the way.
      </p>
      <p>
        We also understand that education is key. To help our customers stay
        ahead of trends, we offer resources and guidance on best practices for
        running a successful convenience store. From selecting the right mix of
        products to ensuring proper compliance with industry regulations, we
        work alongside you to ensure you have the tools and knowledge you need
        to thrive.
      </p>
      <p>
        <strong>Dvash</strong> is committed to maintaining{" "}
        <strong>competitive pricing with no hidden costs</strong>. We believe in
        building long-term relationships based on trust, transparency, and
        consistent service. Our approach to business is simple:{" "}
        <strong>
          provide great products, offer exceptional support, and always
          prioritize the success of our customers
        </strong>
        . You can count on us as a reliable partner dedicated to helping your
        convenience store grow and succeed.
      </p>
      {/* Centered photo of the founders */}
      <div className="founders-photo">
        <img src="/images/henry-richard.jpg" alt="Founders of Dvash" />
      </div>
    </div>
  );
}

export default About;
