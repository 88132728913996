// src/pages/Contact.js
import React, { useEffect, useState } from "react";

function Contact() {
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Obfuscating the email
    const emailUsername = "imran";
    const emailDomain = "dvash.us";
    setEmail(`${emailUsername}@${emailDomain}`);
  }, []);

  return (
    <div className="contact-page">
      <h2>Contact Sales Director</h2>
      <p>
        If you have any inquiries, feel free to contact our Sales Director,
        Imran Asif.
      </p>
      <p>
        Email: <a href={`mailto:${email}`}>{email}</a>
      </p>
    </div>
  );
}

export default Contact;
